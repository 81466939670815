import React, { useState } from 'react';
import { Tabs } from '@clarke-energia/foton';

import { Navigate, useParams } from 'react-router-dom';

import { DOES_NOT_EXIST_PATH } from '@routers/constants';
import { useGetBidSavingsForProposal } from '@hooks/bids/savings';
import { BidWithSavings, Proposal, ValuesReport } from '@hooks/bids/savings/types';
import { formatCurrency, formatDate, formatNumber } from '@utils/text';
import { GuaranteeTypeEnum, ProposalTypeEnum } from '@utils/translators/proposal';
import Skeleton from 'react-loading-skeleton';
import ScopeDisplay from '@components/molecules/compilation-result/scope-content-display';
import ClarkeLogo from '@assets/clarke-logo-extended.svg';
import AthenaLogo from '@assets/athenaenergy.jpeg';
import GetPowerLogo from '@assets/getpower.png';
import YnovaLogo from '@assets/ynova-logo.png';
import LedaxLogo from '@assets/ledax-logo.png';
import ProttonLogo from '@assets/protton-logo.png';
import ProcessInfoDisplay from '@components/molecules/compilation-result/process-info-display';

export interface ParsedBidsData {
  totalEconomy: string;
  traderName: string;
  traderLogo: string;
  traderScore: number;
  npv: string;
  flexibility: string;
  guaranteeType: string;
  traderType: string;
  proposalType: string;
  deadline: string;
  periods: string;
  totalPercentageAmount: string;
  totalCurrencyAmount: string;
  reportForDisplay: ValuesReport;
  cceeCoverTaxes: string;
}

const parsedBidsData = (
  bids: BidWithSavings[],
  proposal: Proposal,
  unitId: string | undefined,
  isInsideScope: boolean,
): ParsedBidsData[] => {
  return (
    bids
      .sort((a, b) => {
        // organize by total economy value descending order
        const aPriceValue =
          a.contractType === 'FIXED_PRICE'
            ? a.savings.fixedPrice.groupReport.totalCurrencyAmount
            : a.savings.guaranteedSaving.groupReport.totalCurrencyAmount;
        const bPriceValue =
          b.contractType === 'FIXED_PRICE'
            ? b.savings.fixedPrice.groupReport.totalCurrencyAmount
            : b.savings.guaranteedSaving.groupReport.totalCurrencyAmount;
        return bPriceValue - aPriceValue;
      })
      //filter by scope
      .filter((bid) => bid.savings.status === 'COMPLETED' && bid.savings.isInsideScope === isInsideScope)
      //format values
      .map((bid) => {
        const proposalUnit = proposal.units.find((unit) => unit.id === unitId);
        const isGroupedResultView = proposalUnit === undefined;
        const unitName = !isGroupedResultView ? proposalUnit.name : '';

        const result = bid.contractType === 'FIXED_PRICE' ? bid.savings.fixedPrice : bid.savings.guaranteedSaving;

        const totalCurrencyAmount =
          unitId === undefined
            ? formatCurrency(result.groupReport.totalCurrencyAmount)
            : formatCurrency(result.unitsReports[unitName]?.totalCurrencyAmount);

        const totalPercentageAmount =
          unitId === undefined
            ? formatNumber(result.groupReport.totalPercentageAmount * 100)
            : formatNumber(result.unitsReports[unitName]?.totalPercentageAmount * 100);

        const totalEconomy = `${totalCurrencyAmount} (${totalPercentageAmount}%)`;
        const traderName = bid.trader.name;
        const traderScore = bid.trader.score;
        const traderLogo = bid.trader.logoUri;
        const npv = result.groupReport.npv ? formatCurrency(result.groupReport.npv) : '-';
        const flexibility = `-${bid.lowerFlexibility}%/+${bid.upperFlexibility}%`;
        const guaranteeType = GuaranteeTypeEnum[bid.guaranteeType];
        const traderType = bid.retailService ? 'Varejista' : 'Atacadista';
        const proposalType = ProposalTypeEnum[bid.proposalType];
        const deadline = formatDate(bid.deadline, 'DD/MM/YYYY HH:mm');
        const periods = bid.periods.map((year) => `${year} anos`).join(', ');
        const cceeCoverTaxes = bid.coverCceeTaxes ? 'Com cobertura' : 'Não há cobertura';

        const reportForDisplay = isGroupedResultView ? result.groupReport : result.unitsReports[unitName];
        return {
          totalEconomy,
          traderName,
          traderScore,
          traderLogo,
          npv,
          flexibility,
          guaranteeType,
          traderType,
          proposalType,
          deadline,
          periods,
          totalPercentageAmount,
          totalCurrencyAmount,
          reportForDisplay,
          cceeCoverTaxes,
        };
      })
  );
};

const CompareProposalsHeader: React.FC<{ processName: string | null }> = ({ processName }) => {
  return (
    <div className="col-span-full mt-8 mb-7 lg:col-span-6">
      <h2 className="mb-4 font-black text-heading-2xlarge">{`Cotação ${processName}`}</h2>
      <p className="text-paragraph-medium">
        As propostas recebidas nessa rodada de cotação são categorizadas como dentro ou fora do escopo solicitado e
        expostas em ordem de qual proposta oferece mais economia.
      </p>
    </div>
  );
};

const SavingsResultSkeleton: React.FC = () => {
  return (
    <div className="col-span-full">
      <Skeleton containerClassName="mb-5" width={'30%'} />
      <Skeleton height="8rem" />
      <Skeleton height="6rem" className="mt-8 mb-7" width={'50%'} />
      <div>
        <Skeleton height="30rem" className="mb-8" />
        <Skeleton height="8rem" />
      </div>
    </div>
  );
};

const CommercialGroupSavingsResultsPage: React.FC = () => {
  const [selectedScenario, setSelectedScenario] = useState<string>('Todas as unidades agrupadas');
  const [unitId, setUnitId] = useState<string | undefined>(undefined);
  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { proposalId } = useParams();

  if (!proposalId) return <Navigate to={DOES_NOT_EXIST_PATH} />;

  const [bids, isLoading, _, proposal, processName] = useGetBidSavingsForProposal(proposalId);
  const bidsInsideScope = parsedBidsData(bids, proposal, unitId, true);
  const bidsNotInsideScope = parsedBidsData(bids, proposal, unitId, false);

  const groupScenario = {
    label: 'Todas as unidades agrupadas',
    onClick: () => {
      setSelectedScenario('Todas as unidades agrupadas');
      setShowPopUp(false);
      setUnitId(undefined);
    },
  };

  const unitScenario = proposal.units.map((unit) => {
    const unitName = unit.name;
    const unitId = unit.id;
    return {
      label: unitName,
      onClick: () => {
        setSelectedScenario(unitName);
        setShowPopUp(false);
        setUnitId(unitId);
      },
    };
  });

  // Caso especial para testes com parceiros de vendas
  function getLogo() {
    if (proposalId === '4bb0b6bb-625d-4bf0-bee8-87969ab95db8') return AthenaLogo;
    if (proposalId === '9033565d-a282-420b-9852-4629aa103823') return YnovaLogo;
    if (proposalId === '91dce07b-f01d-44b5-854d-7ff00055c2e7') return YnovaLogo;
    if (proposalId === '2af279e6-b142-4101-a9d2-5cc6871d13c8') return YnovaLogo;
    if (proposalId === 'd381155f-16e9-4590-8432-0dd5526eacc2') return YnovaLogo;
    if (proposalId === '522d0ca8-293f-4ebb-8198-13bfe1e4f8d4') return GetPowerLogo;
    if (proposalId === '0081bef0-ad07-4bfa-a8af-a57de6a12c63') return LedaxLogo;
    if (proposalId === '5373109e-ea88-4191-93fd-292e2cd2a9be') return LedaxLogo;
    if (proposalId === 'b7456aad-6e79-4c76-b803-d15978249a9f') return LedaxLogo;
    if (proposalId === '1791abe9-bbf6-4ef2-8be1-c2e5fc2f0190') return ProttonLogo;
    if (proposalId === 'f777689e-6dd0-4015-b2a6-23ddf75a5a68') return ProttonLogo;
    if (proposalId === '0598655f-4209-4009-9003-6c954af90819') return ProttonLogo;
    if (proposalId === '380899eb-e760-4990-a996-0664d592bdd5') return ProttonLogo;
    if (proposalId === 'c53775a1-2d52-4553-ac3d-9b09563b8cb7') return ProttonLogo;
    if (proposalId === 'd1021283-38b6-4cd7-80da-4a4fa730cf20') return ProttonLogo;
    if (proposalId === '0c66bc7f-79d0-40d1-a9c4-c31489d22271') return ProttonLogo;
    return ClarkeLogo;
  }

  const selectMenuItens = proposal.units.length > 1 ? [groupScenario, ...unitScenario] : unitScenario;

  return (
    <div className="grid grid-cols-12 lg:pt-8 lg:pb-10 px-[16px] lg:px-[75px] xl:px-[150px]">
      <div className="col-span-full mb-7 xl:col-span-3">
        <img src={getLogo()} alt="Logo" />
      </div>
      {isLoading ? (
        <SavingsResultSkeleton />
      ) : (
        <>
          <div className="col-span-full">
            <ProcessInfoDisplay proposal={proposal} setShowModal={setShowModal} showModal={showModal} />
          </div>
          <CompareProposalsHeader processName={processName} />
          <div className="col-span-full pb-20">
            <Tabs
              selectMenu={{
                label: selectedScenario,
                items: selectMenuItens,
                showPopUp,
                onClick: () => {
                  if (selectMenuItens.length === 1) {
                    setShowPopUp(false);
                    return;
                  }
                  setShowPopUp(true);
                },
              }}
              actionButtons={[
                {
                  buttonType: 'buttonInline',
                  label: 'Informações do escopo',
                  kind: 'primary',
                  className: 'px-2',
                  onClick: () => setShowModal(true),
                },
              ]}
              tabs={[
                { label: 'Dentro do escopo', children: <ScopeDisplay bids={bidsInsideScope} type="INSIDE" /> },
                { label: 'Fora do escopo', children: <ScopeDisplay bids={bidsNotInsideScope} type="OUTSIDE" /> },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CommercialGroupSavingsResultsPage;
